import React, { FC, useState, useEffect } from 'react';
import forgotPasswordSchema from '../../utils/ValidationSchema/forgotPasswordValidation';
import { useFormik } from 'formik';
import TextInput from '../common/InputFields/TextInput';
import Button from '../common/Buttons/Button';
import ErrorMsg from '../common/ErrorMsg';
import { getQueryString } from '../../utils/helper';
import { toast } from 'react-toastify';
import { checkToken } from '../../services/ForgotPasswordServices';
import { updatePass } from '../../services/ForgotPasswordServices';
import { navigate, Link } from 'gatsby';
import PageLoader from '../common/Loader/PageLoader';

const PasswordContent = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [tokenCheck, settokenCheck] = useState(false);
  const [userData, setUserData] = useState('');
  const redirectToken = getQueryString('key');
  let tokenData = {
    token: redirectToken,
  };

  useEffect(() => {
    fetchValidToken();
  }, []);

  const fetchValidToken = async () => {
    try {
      setLoader(true);
      const resp: any = await checkToken(tokenData);
      // console.log(resp.data.status);
      if (resp.data.status === true) {
        settokenCheck(true);
        setLoader(false);
        setUserData(resp.data.user);
      }
    } catch (error: any) {
      settokenCheck(false);
      setLoader(false);
    }
  };
  //   console.log(tokenCheck);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmpassword: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log(values);
      // alert(JSON.stringify(values, null, 2));
      const { password, confirmpassword } = values;
      let passData = {
        password: btoa(password),
        user: userData,
      };
      try {
        const userPassUpdate: any = await updatePass(passData);
        if (userPassUpdate.data.status === true) {
          toast.success('password updated successfully');
          resetForm();
          setTimeout(() => {
            navigate('/login');
          }, 5000);
        } else {
          toast.error('Password not updated. Please try again!');
        }
      } catch (error: any) {
        toast.error(error.msg);
      }
    },
  });
  return (
    <>
      {loader ? (
        <div className="min-h-[60vh] h-[60vh] flex justify-center items-center">
          <PageLoader />
        </div>
      ) : (
        <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] lg:mt-[60px] mt-[20px] lg:mb-[80px] mb-[30px] contain log-ui">
          <h1 className="md:text-center text-left text-[40px]  md:mb-5 mb-2 text-black font-sofiaSemibold  font-semiBold">
            Reset Password
          </h1>
          <div className="bg-[#F4F4F4] lg:p-[25px] mx-auto xl:w-[908px] md:max-w-[908px] md:w-[90%] w-full rounded-[21px]">
            {tokenCheck ? (
              <div className="w-full mx-auto md:px-[20px] py-[30px] px-[15px]">
                <form
                  name="reset_password"
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <div className="w-full relative">
                    <TextInput
                      type={'password'}
                      name={'password'}
                      id={'password'}
                      label={'New Password'}
                      placeholder={'New Password'}
                      className={'w-full input'}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.errors.password && formik?.touched.password ? (
                      <ErrorMsg value={formik.errors.password} />
                    ) : null}
                  </div>
                  <div className="w-full mt-4 relative">
                    <TextInput
                      type={'password'}
                      name={'confirmpassword'}
                      id={'confirmpassword'}
                      label={'Confirm Password'}
                      placeholder={'Confirm Password'}
                      className={'w-full input'}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmpassword}
                    />
                    {formik.errors.confirmpassword &&
                    formik?.touched.confirmpassword ? (
                      <ErrorMsg value={formik.errors.confirmpassword} />
                    ) : null}
                  </div>
                  <div>
                    <Button
                      type="submit"
                      text="Submit"
                      loading={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                      className="submit-btn bg-black hover:bg-primary text-[20px] font-sofiaMedium font-medium w-full  py-3 text-white rounded-[35px] mt-8 mb-3 transition-all ease-in"
                    />
                  </div>
                </form>
              </div>
            ) : (
              <div className="checkTokenStatus">
                <h2>
                  Link is expired. Please click on the below link to reset your
                  password.
                </h2>
                <Link
                  to="/forgot-password"
                  className="underline w-fit text-[#8D8D8D] hover:md:text-black font-sofiaRegular font-regular text-18 text-left"
                >
                  Forgot password
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordContent;
